/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #fff;
  background: #111;
}
.card {
  max-width: 45rem;
}
@media (max-width: 45rem) {
  .card {
    max-width: 100%;
    padding: 3rem 2rem;
  }
}
.card__title {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.03em;
}
.card__title:after {
  content: "/";
  opacity: 0.5;
}
@media (max-width: 25rem) {
  .card__title {
    font-size: 2rem;
  }
}
.card__list {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  margin: 2rem 0;
  padding: 0;
}
@media (min-width: 45rem) {
  .card__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.card__list h5 {
  margin: 0 0 0.25rem 0;
  padding-bottom: 0.25rem;
  font-weight: normal;
  font-size: 0.8rem;
  text-transform: uppercase;
  opacity: 0.5;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.card__list a {
  color: #fff;
}
.card__list a:hover {
  text-decoration: none;
  opacity: 0.9;
}
.card__meta {
  font-size: 0.8rem;
  opacity: 0.38;
}
/* inter-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-cyrillic-ext-400-normal.2fad6a96.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* inter-cyrillic-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-cyrillic-400-normal.9e8ce39f.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* inter-greek-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-greek-ext-400-normal.9b3586bf.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-greek-400-normal.b8345803.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-vietnamese-400-normal.dc5a3259.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* inter-latin-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-latin-ext-400-normal.095ce74d.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* inter-latin-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('inter-latin-400-normal.ca5c0bef.woff2') format('woff2'), url('inter-all-400-normal.f016884f.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-cyrillic-ext-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-cyrillic-ext-900-normal.2fad6a96.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* inter-cyrillic-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-cyrillic-900-normal.9e8ce39f.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* inter-greek-ext-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-greek-ext-900-normal.9b3586bf.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-greek-900-normal.b8345803.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-vietnamese-900-normal.dc5a3259.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* inter-latin-ext-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-latin-ext-900-normal.095ce74d.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* inter-latin-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('inter-latin-900-normal.ca5c0bef.woff2') format('woff2'), url('inter-all-900-normal.5b90e912.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.083be2b1.css.map */
