@import './reset.css';

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;

    color: #fff;
    background: #111;
}

.card {
    max-width: 45rem;

    @media (max-width: 45rem) {
        max-width: 100%;
        padding: 3rem 2rem;
    }

    &__title {
        font-size: 3rem;
        font-weight: 900;
        line-height: 1;
        letter-spacing: -0.03em;

        &:after {
            content: '/';
            opacity: 0.5;
        }

        @media (max-width: 25rem) {
            font-size: 2rem;
        }
    }

    // &__text {
    //     margin: 2rem 0;
    //     font-size: 1.25rem;
    //     line-height: 1.6;
    //     letter-spacing: -0.02em;
    // }

    &__list {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr;

        @media (min-width: 45rem) {
            grid-template-columns: repeat(3, 1fr);
        }

        margin: 2rem 0;
        padding: 0;

        h5 {
            margin: 0 0 0.25rem 0;
            padding-bottom: 0.25rem;
            font-weight: normal;
            font-size: 0.8rem;
            text-transform: uppercase;
            opacity: 0.5;
            border-bottom: 1px solid rgba(#fff, 0.2);
        }

        a {
            color: #fff;

            &:hover {
                text-decoration: none;
                opacity: 0.9;
            }
        }
    }

    &__meta {
        font-size: 0.8rem;
        opacity: 0.38;
    }
}
